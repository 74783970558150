import React, { useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { getLabels } from '../../redux/reducers/configuration'

import Tabs from './Tabs'
import NewReservationForm from './NewReservationForm'

import { ReactComponent as HotelIcon } from '../../assets/icons/bed.svg'
import { ReactComponent as TourIcon } from '../../assets/icons/tour-bus.svg'
import { ReactComponent as CampIcon } from '../../assets/icons/campground.svg'

const utfDiff = 660 - moment().utcOffset()
const now = moment().add(utfDiff, 'minutes')
const tomorrow = now.add(1, 'days')

const Banner = function({ widgetType }) {
  const labels = useSelector(getLabels)
  const initialOptions = JSON.parse(localStorage.getItem('vitQueryCardParams'))

  const addedFromStart = +initialOptions?.minimumNights
    ? +initialOptions.minimumNights
    : initialOptions.defaultStayLength
    ? +initialOptions.defaultStayLength
    : 3

  const isTrade =
    initialOptions?.widgetType === 'vit-trade' &&
    process.env.REACT_APP_TRADE_TARGET_URL

  const initialArrivalDate =
    get(initialOptions, 'arrivalDate') &&
    moment(initialOptions.arrivalDate).isAfter(now, 'days')
      ? moment(initialOptions.arrivalDate)
      : tomorrow

  const initialDepartureDate =
    get(initialOptions, 'departureDate') &&
    moment(initialOptions.departureDate).isAfter(initialArrivalDate, 'days')
      ? moment(initialOptions.departureDate)
      : moment(initialArrivalDate).add(addedFromStart, 'days')

  const [values, setValues] = useState({
    arrivalDate: initialArrivalDate,
    departureDate: initialDepartureDate,
    rooms: [{ adults: 2, children: 0, infants: 0 }],
    promoCodes: []
  })

  const [selectedTab, setSelectedTab] = useState(
    initialOptions?.initialTab &&
      ['hotelsAndTours', 'tours', 'campground'].some(
        option => option === initialOptions.initialTab
      )
      ? initialOptions?.initialTab
      : 'hotelsAndTours'
  )

  const searchIncludesHotel =
    selectedTab === 'hotelsAndTours' || selectedTab === 'hotels'

  const formProps = {
    initialOptions,
    values,
    onValuesChange: handleValuesChange,
    onSubmit: submit,
    useCalendarSearch: searchIncludesHotel,
    widgetType,
    addedFromStart: addedFromStart
  }

  const tabs = [
    {
      id: 'hotelsAndTours',
      name: 'Hotels',
      visible: true,
      content: (
        <NewReservationForm
          {...formProps}
          allowMultiRoom
          showDepartureTrigger
        />
      ),
      icon: <HotelIcon />
    },
    {
      id: 'tours',
      name: labels.tours,
      visible: true,
      content: <NewReservationForm {...formProps} />,
      icon: <TourIcon />
    },
    {
      id: 'campground',
      name: 'Campground',
      visible: !isTrade,
      icon: <CampIcon />,
      content: (
        <NewReservationForm
          {...formProps}
          isCampground
          allowMultiRoom
          showDepartureTrigger
        />
      )
    }
  ]

  const isCampground = selectedTab === 'campground'

  function submit() {
    const params = new URLSearchParams()
    const { arrivalDate, departureDate, rooms, promoCodes } = values

    params.append('startDate', arrivalDate.format('YYYY-MM-DD'))
    params.append(
      'endDate',
      departureDate
        ? departureDate.format('YYYY-MM-DD')
        : arrivalDate.add(2, 'days').format('YYYY-MM-DD')
    )
    params.append(
      'rooms',
      rooms
        ?.map(
          room =>
            `${room.adults}|${room.children}${
              isCampground && room.infants ? `|${room.infants}` : ''
            }`
        )
        ?.join(',') || '2|0'
    )

    if (promoCodes.length > 0) {
      params.append('promoCodes', promoCodes.join(','))
    }

    if (!isCampground) {
      params.append('accommodation', selectedTab)
      if (selectedTab === 'tours') {
        params.append('flow', 'E')
      } else {
        params.append('flow', 'AE')
      }
      if (initialOptions?.propertyCode && searchIncludesHotel) {
        params.append('hotelCode', initialOptions.propertyCode)
      }
    }
    const paramString = params.toString()

    let basePath = isTrade
      ? process.env.REACT_APP_TRADE_TARGET_URL
      : process.env.REACT_APP_TARGET_URL
    if (initialOptions?.targetUrl) {
      basePath = initialOptions?.targetUrl
    }
    if (isCampground) {
      window.location.href = `${process.env.REACT_APP_CAMPGROUND_TARGET_URL}?${paramString}`
      return
    }
    if (isTrade) {
      const entryPoint = selectedTab === 'tours' ? '/tours' : '/accommodations'
      basePath += entryPoint
    } else {
      const entryPoint = selectedTab === 'tours' ? '/tours' : ''
      basePath += entryPoint
    }
    window.location = `${basePath}?${paramString}`
  }

  function handleValuesChange(values) {
    setValues(values)
  }

  return (
    <div className="reservation-banner">
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        widgetType={widgetType}
      />
    </div>
  )
}
export default Banner
