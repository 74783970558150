import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { configurationData } from '../../redux/reducers/configuration'
import { fetchConfiguration } from '../../redux/actions/fetchConfiguration'

import ErrorBoundary from '../../components/elements/ErrorBoundary'
import BrandLoader from '../../components/elements/BrandLoader'
import Banner from '../../components/elements/Banner'
import { packageData } from '../../redux/reducers/packages'
import { fetchPackages } from '../../redux/actions/fetchPackages'

function App({ widgetType }) {
  const configuration = useSelector(state => configurationData(state)),
    packages = useSelector(packageData),
    dispatch = useDispatch()

  useEffect(() => {
    // doing it this way rather then only on load to prevent having to wait when using hot reload.
    if (!configuration.data && !configuration.loading && !configuration.error) {
      dispatch(fetchConfiguration())
    }
  }, [dispatch, configuration])

  useEffect(() => {
    if (!packages.data && !packages.loading && !packages.error) {
      dispatch(fetchPackages())
    }
  }, [dispatch, packages])

  return (
    <div
      className={`vit__query-reservation-banner ${widgetType}`}
      data-testid="vit__query-reservation-banner"
    >
      {configuration.loading ? (
        <BrandLoader />
      ) : configuration.error ? (
        <ErrorBoundary errorMessage={configuration.error} />
      ) : (
        <Banner widgetType={widgetType} />
      )}
    </div>
  )
}

export default App
