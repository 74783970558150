import React, { useEffect, useState, Fragment } from 'react'

const TRANSITION_LENGTH = 250

const Tabs = ({ selectedTab, tabs, onTabChange, widgetType }) => {
  const [currentTab, setCurrentTab] = useState(selectedTab)
  const [transitioning, setTransitioning] = useState(false)
  const tabContent = tabs.find(tab => tab.id === currentTab)?.content

  function handleTabClick(tab) {
    onTabChange(tab.id)
  }

  useEffect(() => {
    setTransitioning(true)
    const timeout = setTimeout(() => {
      setTransitioning(false)
      setCurrentTab(selectedTab)
    }, TRANSITION_LENGTH)

    return () => {
      clearTimeout(timeout)
    }
  }, [selectedTab])

  return (
    <div className={`tabs-container ${widgetType}`}>
      <div className={`tabs ${widgetType}`}>
        {tabs.map(tab => (
          <Fragment key={tab.id}>
            {tab.visible && (
              <button
                className={`tab ${tab.id === currentTab ? 'active' : ''} ${
                  transitioning ? 'transitioning' : ''
                } ${widgetType}`}
                key={tab.id}
                onClick={() => handleTabClick(tab)}
              >
                <div className="inner">
                  {tab.icon}
                  <p className='tab-name'>{tab.name}</p>
                </div>
              </button>
            )}
          </Fragment>
        ))}
      </div>
      <div className="tab-content-container">
        <div className={`tab-content ${transitioning ? 'transitioning' : ''}`}>
          {tabContent}
        </div>
      </div>
    </div>
  )
}

export default Tabs
