import axios from 'axios'

const endpoints = {
  configuration: process.env.REACT_APP_ENDPOINT_DOLLI + '/config?',
  packages:
    process.env.REACT_APP_ENDPOINT_DOLLI + `/content/packages?clientId=${process.env.REACT_APP_FLOW_CLIENT_ID}`,
  hotelStay: process.env.REACT_APP_ENDPOINT_DOLLI + '/hotel/hotel-stay'
}

export const configuration = currentLanugage => {
  return axios.get(endpoints.configuration + currentLanugage.toString())
}

export const packages = () => {
  return axios.get(endpoints.packages)
}

export const availability = (hotelCode, params) =>
  axios.get(`${endpoints.hotelStay}/${hotelCode}/calendar?${params}`)

export default endpoints
