import { get } from 'lodash'
import { packages } from '../../api/api'

export const FETCH_PACKAGES_BEGIN = 'FETCH_PACKAGES_BEGIN'
export const FETCH_PACKAGES_SUCCESS = 'FETCH_PACKAGES_SUCCESS'
export const FETCH_PACKAGES_ERROR = 'FETCH_PACKAGES_ERROR'

export const fetchPackages = () => async dispatch => {
  dispatch({ type: FETCH_PACKAGES_BEGIN })

  return packages()
    .then(res => {
      const data = get(res, ['data', 'hydra:member'])
      if (data) {
        dispatch({
          type: FETCH_PACKAGES_SUCCESS,
          payload: data
        })
      } else {
        dispatch({
          type: FETCH_PACKAGES_ERROR,
          payload:
            'There was an error retrieving promo codes - please wait a moment, then try again.'
        })
      }
    })
    .catch(err => {
      dispatch({ type: FETCH_PACKAGES_ERROR, payload: err })
    })
}
