import {
  FETCH_AVAILABILITY_BEGIN,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_ERROR,
  CLEAR_AVAILABILITY_DATA
} from '../actions/fetchAvailability'

const initialState = { loading: {}, data: {}, errors: {} }

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_AVAILABILITY_BEGIN:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.month]: true
        }
      }
    case FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.month]: false
        },
        data: {
          ...state.data,
          [action.payload.month]: action.payload.data
        }
      }
    case FETCH_AVAILABILITY_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.month]: false
        },
        errors: {
          ...state.errors,
          [action.payload.month]: action.payload.error
        }
      }
    case CLEAR_AVAILABILITY_DATA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const selectAvailabilityState = state => state.availabilityData
