import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { getLabels } from '../../redux/reducers/configuration'
import Counter from './Counter'

import { ReactComponent as GuestIcon } from '../../assets/icons/guest-icon.svg'

const RoomsInput = function({
  value,
  onChange,
  maxRoomGuests,
  widgetType,
  allowMultiRoom,
  isCampground
}) {
  const labels = useSelector(getLabels)
  const [open, setOpen] = useState(false)
  const roomInputRef = useRef(null)
  const roomSelectorRef = useRef(null)
  const rooms = _.cloneDeep(value)

  const counterLabels = {
    addAdults: labels.addAdult,
    removeAdults: labels.removeAdult,
    addChildren: labels.addChild,
    removeChildren: labels.removeChild,
    addInfants: 'Add Infants',
    removeInfants: 'Remove Infants'
  }

  const roomsCount = _.get(rooms, 'length', 0)
  let adultsCount = 0,
    childrenCount = 0,
    infantCount = 0
  if (roomsCount > 0) {
    rooms.forEach(room => {
      adultsCount += room.adults
      childrenCount += room.children
      infantCount += room?.infants ?? 0
    })
  }

  const handleButtonClick = () => {
    setOpen(true)
    setTimeout(function() {
      roomSelectorRef.current.focus()
    })
  }

  const handleGuestChange = (roomIndex, guestKey, newValue) => {
    rooms[roomIndex][guestKey] = newValue
    onChange(rooms)
  }

  const handleAddRoom = () => {
    rooms.push({ adults: 2, children: 0, infants: 0 })
    onChange(rooms)
  }

  const handleRemoveRoom = index => {
    const newRooms = [...rooms]
    newRooms.splice(index, 1)
    onChange(newRooms)
  }

  const focusCheck = () => {
    setTimeout(function() {
      const ref = roomSelectorRef.current
      if (ref) {
        const hasFocus = ref.contains(document.activeElement)
        if (!hasFocus) {
          setOpen(false)
        }
      }
    })
  }

  return (
    <div className="query-reservation-banner__rooms-input">
      <button
        className="input-button"
        onClick={handleButtonClick}
        ref={roomInputRef}
        aria-haspopup="true"
        aria-expanded={open}
      >
        <div className="guest-btn-inner">
          {widgetType === 'banner-alt' ? (
            <div className={`content-alt ${!allowMultiRoom ? 'left' : ''}`}>
              <GuestIcon className="icon" />
              <>
                <div className="inner-content">
                  <span className="number">{adultsCount}</span>&nbsp;
                  <span>
                    {adultsCount === 1 ? labels.adult : labels.adults}
                  </span>
                  ,&nbsp;
                  <span className="number">{childrenCount}</span>&nbsp;
                  <span>
                    {childrenCount === 1 ? labels.child : labels.children}
                  </span>
                  {isCampground && infantCount > 0 ? (
                    <>
                      ,&nbsp;
                      <span className="number">{infantCount}</span>&nbsp;
                      <span>
                        {infantCount === 1 ? labels.infant : labels.infants}
                      </span>
                    </>
                  ) : null}
                </div>
                {allowMultiRoom && (
                  <>
                    <div>|</div>
                    <div>
                      <span className="number">{roomsCount}</span>
                      <span>{roomsCount > 1 ? ' Rooms' : ' Room'}</span>
                    </div>
                  </>
                )}
              </>
            </div>
          ) : (
            <div className="content">
              <>
                <div className="inner-content">
                  {allowMultiRoom && (
                    <>
                      <span className="number">{roomsCount}</span>&nbsp;
                      <span>{roomsCount > 1 ? ' Rooms' : ' Room'},</span>
                      &nbsp;
                    </>
                  )}
                  <span className="number">{adultsCount}</span>&nbsp;
                  <span>
                    {adultsCount === 1 ? labels.adult : labels.adults}
                  </span>
                  ,&nbsp;
                  <span className="number">{childrenCount}</span>&nbsp;
                  <span>
                    {childrenCount === 1 ? labels.child : labels.children}
                  </span>
                  {isCampground && infantCount > 0 ? (
                    <>
                      ,&nbsp;
                      <span className="number">{infantCount}</span>&nbsp;
                      <span>
                        {infantCount === 1 ? labels.infant : labels.infants}
                      </span>
                    </>
                  ) : null}
                </div>
              </>
              <GuestIcon className="icon" />
            </div>
          )}
        </div>
      </button>
      <div
        className={`room-selector ${open ? 'open' : ''}`}
        ref={roomSelectorRef}
        tabIndex={-1}
        onBlur={focusCheck}
      >
        <div className="rooms">
          {rooms &&
            rooms.length > 0 &&
            rooms.map((room, roomIndex) => {
              return (
                <div className="room" key={roomIndex}>
                  {rooms.length > 1 && (
                    <div className="title">
                      <div>{`${labels.room} ${roomIndex + 1}`}</div>
                      <button
                        onClick={() => handleRemoveRoom(roomIndex)}
                        aria-label={labels.removeRoom}
                      >
                        {labels.removeRoom} -
                      </button>
                    </div>
                  )}
                  <div className="guests">
                    {Object.keys(room).map(guestKey => {
                      if (guestKey === 'infants' && !isCampground) {
                        return null
                      }
                      const labelKey =
                        guestKey.charAt(0).toUpperCase() + guestKey.slice(1)
                      const addLabel = counterLabels[`add${labelKey}`]
                      const removeLabel = counterLabels[`remove${labelKey}`]
                      return (
                        <div className="guest" key={guestKey}>
                          <div className="label">{labels[guestKey]}</div>
                          <Counter
                            addLabel={addLabel}
                            subtractLabel={removeLabel}
                            value={room[guestKey]}
                            canBeZero={guestKey !== 'adults'}
                            maxValue={
                              guestKey === 'children'
                                ? +maxRoomGuests - room?.adults
                                : +maxRoomGuests - room?.children
                            }
                            onChange={val =>
                              handleGuestChange(roomIndex, guestKey, val)
                            }
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
        </div>
        {allowMultiRoom && (
          <button
            className="add-room"
            aria-label={labels.addRoom}
            disabled={rooms?.length >= 7}
            onClick={handleAddRoom}
          >
            {labels.addRoom} +
          </button>
        )}
      </div>
    </div>
  )
}

export default RoomsInput
