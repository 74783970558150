import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import RoomsInput from './RoomsInput'
// import SpecialCodes from './SpecialCodes'
import Dates from './dates/Dates'
import { getLabels } from '../../redux/reducers/configuration'
// import { getPackages } from '../../redux/reducers/packages'
import { clearAvailabilityData } from '../../redux/actions/fetchAvailability'
import moment from 'moment'

const NewReservationForm = ({
  initialOptions,
  values,
  onValuesChange,
  onSubmit,
  useCalendarSearch,
  widgetType,
  addedFromStart,
  isCampground,
  allowMultiRoom = false,
  showDepartureTrigger
}) => {
  const labels = useSelector(getLabels)
  // const packages = useSelector(getPackages)
  // const validCodes = packages.map(item => item.id)
  const minNights = get(initialOptions, 'minNights', 1)
  const defaultNights = get(initialOptions, 'defaultNights', 3)
  // const [specialCodesIsVisible, setSpecialCodesIsVisible] = useState(false)

  const dispatch = useDispatch()

  function handleChange(key, value) {
    dispatch(clearAvailabilityData())
    onValuesChange({
      ...values,
      [key]: value
    })
  }

  function handleDateChange({ startDate, endDate }) {
    onValuesChange({
      ...values,
      arrivalDate: startDate,
      departureDate: endDate || moment(startDate).add(addedFromStart, 'days')
    })
  }

  return (
    <div className={`fields-container ${widgetType}`}>
      <div className="fields">
        <div className={`inputs ${isCampground ? 'campground-inputs' : ''}`}>
          <div className="dates field">
            <Dates
              startDate={values.arrivalDate}
              endDate={values.departureDate}
              minNights={minNights}
              onChange={handleDateChange}
              guestCounts={values?.rooms?.[0] || { adults: 2, children: 0 }}
              useCalendarSearch={useCalendarSearch}
              defaultNights={defaultNights}
              widgetType={widgetType}
              showDepartureTrigger={showDepartureTrigger}
            />
          </div>

          <div className="guests field">
            <div className="label-container">
              <label>{labels.guests}</label>
            </div>
            <div className="input-container">
              <RoomsInput
                value={values.rooms}
                onChange={rooms => handleChange('rooms', rooms)}
                maxRoomGuests={initialOptions?.maxRoomGuests || 6}
                widgetType={widgetType}
                allowMultiRoom={allowMultiRoom}
                isCampground={isCampground}
              />
            </div>
          </div>
        </div>

        <button className="btn btn-primary submit" onClick={onSubmit}>
          {labels.checkRates}
        </button>
        {/*<SpecialCodes*/}
        {/*  isExpanded={specialCodesIsVisible}*/}
        {/*  handleExpandEvent={setSpecialCodesIsVisible}*/}
        {/*  labels={labels}*/}
        {/*  validCodes={validCodes}*/}
        {/*  promoCodes={values.promoCodes}*/}
        {/*  setPromoCodes={promoCodes => handleChange('promoCodes', promoCodes)}*/}
        {/*/>*/}
      </div>
    </div>
  )
}

export default NewReservationForm
