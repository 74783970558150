import moment from 'moment'
import { get } from 'lodash'
import { availability } from '../../api/api'
export const FETCH_AVAILABILITY_BEGIN = 'FETCH_AVAILABILITY_BEGIN'
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS'
export const FETCH_AVAILABILITY_ERROR = 'FETCH_AVAILABILITY_ERROR'
export const CLEAR_AVAILABILITY_DATA = 'CLEAR_AVAILABILITY_DATA'

// Australia Central Timezone
const acstOffset = -570

export const fetchAvailability = ({
  monthKey,
  adults,
  children,
  numberOfNights,
  propertyCode
}) => dispatch => {
  dispatch({ type: FETCH_AVAILABILITY_BEGIN, payload: { month: monthKey } })

  const startDate = moment(monthKey, 'YYYY-MM').startOf('month')

  const userTimeZoneOffset = new Date(
    startDate.clone().toDate()
  ).getTimezoneOffset()
  const diffInMinutes = acstOffset - userTimeZoneOffset
  const todayInAustralia = moment().subtract(diffInMinutes, 'minutes')
  const startParam = startDate.isAfter(todayInAustralia, 'days')
    ? startDate.format('YYYY-MM-DD')
    : todayInAustralia.format('YYYY-MM-DD')

  const params = new URLSearchParams()
  params.append('id', propertyCode)
  params.append('hotelCode', propertyCode)
  params.append('currency', 'AUD')
  params.append('startDate', startParam)
  params.append(
    'endDate',
    moment(monthKey, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD')
  )
  params.append('adults', adults.toString())
  params.append('children', children.toString())
  params.append('numberOfNights', numberOfNights)
  params.append('hotelProvider', '2')
  params.append('clientId', process.env.REACT_APP_FLOW_CLIENT_ID)
  params.append('chainCode', 'VHR')

  return availability(propertyCode, params.toString())
    .then(res => {
      const days = {}
      get(res, 'data["hydra:member"]', []).forEach(hotelStay => {
        if (hotelStay?.availability?.status === 'Open') {
          const priceData = get(hotelStay, 'price', [])
          priceData.forEach(data => {
            const price = data.amountAfterTax
            const start = moment(data.start)
            const endDate = moment(data.end)

            for (
              let day = start;
              day.diff(endDate, 'days') <= 0;
              day.add(1, 'days')
            ) {
              days[day.format('DD')] = price
            }
          })
        }
      })

      dispatch({
        type: FETCH_AVAILABILITY_SUCCESS,
        payload: {
          month: monthKey,
          data: days
        }
      })
    })
    .catch(() => {
      // we're going to treat errors as no availability as the api seems to 404 in that case.
      dispatch({
        type: FETCH_AVAILABILITY_SUCCESS,
        payload: {
          month: monthKey,
          data: {}
        }
      })
    })
}

export const clearAvailabilityData = () => dispatch => {
  dispatch({ type: CLEAR_AVAILABILITY_DATA })
}
