import React from 'react'

const Counter = function({
  value,
  canBeZero,
  onChange,
  subtractLabel,
  addLabel,
  maxValue
}) {
  const handleMinusButtonClick = () => {
    if (value > 1 || (canBeZero && value !== 0)) {
      onChange(value - 1)
    }
  }

  const handlePlusButtonClick = () => {
    if (value + 1 <= maxValue) {
      onChange(value + 1)
    }
  }

  return (
    <div className="query-reservation-banner__counter">
      <button onClick={handleMinusButtonClick} aria-label={subtractLabel}>
        −
      </button>
      <div className="count">{value}</div>
      <button onClick={handlePlusButtonClick} aria-label={addLabel}>
        +
      </button>
    </div>
  )
}

export default Counter
