import React from 'react'
import calIcon from '../../../assets/icons/calendar.svg'

export const triggerDateFormats = {
  weekday: 'dddd',
  mainDisplay: 'D MMMM',
  longDisplay: 'ddd, D MMMM'
}

const DateSelectionTrigger = ({
  date,
  handleOpenDatePicker,
  label,
  widgetType,
  borderLeft,
  isDeparture,
  useCalendarSearch
}) => {
  const renderLabel = () => {
    if (widgetType === 'banner-alt' && isDeparture) {
      return <p></p>
    }

    if (widgetType === 'banner-alt') {
      return <p className={`label`}>{useCalendarSearch ? 'Arrival & Departure' : 'Date'}</p>
    }
    if (isDeparture) {
      return <p className={`label u-md-up`}>Departure</p>
    }
    return (
      <>
        <p className={`label u-md-max`}>{useCalendarSearch ? 'Arrival & Departure' : 'Date'}</p>
        <p className={`label u-md-up`}>{useCalendarSearch ? 'Arrival' : 'Date'}</p>
      </>
    )
  }

  const renderLeftCalClassNames = () => {
    if (isDeparture) {
      return 'hidden'
    }

    if (widgetType === 'banner-alt') {
      return ''
    }

    return 'mobile'
  }

  return (
    <div
      className={`date-selection-container ${borderLeft ? 'border-left' : ''} ${
        !label ? 'flex-end' : ''
      } ${isDeparture ? 'departure' : ''}`}
    >
      {renderLabel()}
      <button
        className={`btn date-selection-button  ${date ? 'has-value' : ''}`}
        onClick={handleOpenDatePicker}
      >
        <div className="u-flex button-content">
          <img
            src={calIcon}
            alt={label}
            className={`cal-icon ${renderLeftCalClassNames()}`}
          />

          {date ? (
            <div>
              <p className="u-remove-margin date desktop">
                {date.format(
                  widgetType === 'banner-alt'
                    ? triggerDateFormats.longDisplay
                    : triggerDateFormats.mainDisplay
                )}
              </p>
              <p className="u-remove-margin date mobile">
                {date.format(triggerDateFormats.longDisplay)}
              </p>
            </div>
          ) : (
            <p className="u-remove-margin placeholder">{label}</p>
          )}
          {widgetType === 'banner' && (
            <img
              src={calIcon}
              alt={label}
              className="cal-icon self-end u-md-up"
            />
          )}
        </div>
      </button>
    </div>
  )
}

export default DateSelectionTrigger
