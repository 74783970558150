import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js'
import 'airbnb-browser-shims'

import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'

import App from './containers/structure/App'
import configureStore from './redux/store'

import './styles/scss/base/base.scss'
import './styles/scss/base/typography.scss'
import './styles/scss/base/utilities.scss'
import './styles/scss/elements/roomsInput.scss'
import './styles/scss/elements/counter.scss'
import './styles/scss/elements/special-codes.scss'
import './styles/scss/elements/tabs.scss'

const store = configureStore()

const targets = document.querySelectorAll(`[data-name*="react-widget"]`)

const renderApp = () => {
  for (const target of targets) {
    render(
      <Provider store={store}>
        <App widgetType={target.getAttribute('data-type') || 'banner'} />
      </Provider>,
      target
    )
  }
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/structure/App', renderApp)
}

renderApp()
