import {
  FETCH_PACKAGES_BEGIN,
  FETCH_PACKAGES_SUCCESS,
  FETCH_PACKAGES_ERROR
} from '../actions/fetchPackages'

const initialState = {
  loading: false,
  error: null,
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PACKAGES_BEGIN:
      return Object.assign({}, state, {
        loading: true
      })
    case FETCH_PACKAGES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload
      })
    case FETCH_PACKAGES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      })
    default:
      return state
  }
}

export const packageData = state => state.packageData
export const getPackages = state => state.packageData.data || []
