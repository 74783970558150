import React from 'react'

const AvailabilityCalendarDay = ({ date, price, isDateAvailable }) => {
  return (
    <div
      className={`u-full-width u-full-height u-padding-x-2 u-padding-bottom-1 u-text-right ${
        !isDateAvailable ? 'u-background-strike-through' : ''
      }`}
    >
      <div
        className={`u-remove-margin u-font-sm u-font-heading u-full-height u-flex u-flex-column ${
          !isDateAvailable ? 'u-color-off-tan' : ''
        }`}
      >
        <div className="date u-bold u-flex-fill">{date.format('D')}</div>
        {price && (
          <div className="price u-font-xxs u-font-base-md-up">${price}</div>
        )}
      </div>
    </div>
  )
}

export default AvailabilityCalendarDay
