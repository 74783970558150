import { combineReducers } from 'redux'
import configurationData from './configuration'
import packageData from './packages'
import availabilityData from './availability'

const rootReducer = combineReducers({
  availabilityData,
  configurationData,
  packageData
})

export default rootReducer
